import React, { Component }  from 'react'
import GitHubIcon from '@material-ui/icons/GitHub'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import TwitterIcon from '@material-ui/icons/Twitter'

import { about } from '../../portfolio'
import './About.css'
import pdf from '../Access/cboonnag_cv.pdf'

const About = () => {
  const {photo,name, role, description, resume, social } = about

  return (
    <div className='about center'>
      {photo && (
        <img height="200px" alt="Avatar placeholder" class = 'about__pic' src={photo} />
      )}
      {name && (
        <h1>
          Hi, I am <span className='about__name'>{name}</span>
        </h1>
      )}

      {role && <h2 className='about__role'>A {role}</h2>}
      <p className='about__desc'>{description && description}</p>

      <div className='about__contact center'>

        {social && (
          <>
            {social.github && (
              <a
                href={social.github}
                aria-label='github'
                className='link link--icon'
              >
                <GitHubIcon />
              </a>
            )}

            {social.linkedin && (
              <a
                href={social.linkedin}
                aria-label='linkedin'
                className='link link--icon'
              >
                <LinkedInIcon />
              </a>
            )}

            {social.twitter && (
              <a
                href={social.twitter}
                aria-label='twitter'
                className='link link--icon'
              >
                <TwitterIcon />
              </a>
            )}
          </>
        )}
      </div> 
      <div className='about__contact center'>
        {resume && (
          <a href={pdf}>
            <span type='button' className='btn btn--outline'>
              CV
            </span>
          </a>
        )}

        {resume && (
          <a href={'https://scholar.google.com/citations?user=fZ9trBIAAAAJ&hl=en'}>
            <span type='button' className='btn btn--outline'>
              Google scholar
            </span>
          </a>
        )}

        {resume && (
          <a href={'https://pubmed.ncbi.nlm.nih.gov/?term=Boonnag+C&cauthor_id=30689543'}>
            <span type='button' className='btn btn--outline'>
              PubMed 
            </span>
          </a>
        )}    
      </div>
    </div>
  )
}

export default About
