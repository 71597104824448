import React, { Component }  from 'react';
import { contact } from '../../portfolio'
import './Contact.css'

import EmailIcon from '@material-ui/icons/Email'

const Contact = () => {
  if (!contact.email) return null

  return (
    <section className='section contact center' id='contact'>
      <h3 className='section__title'>Let’s Get In Touch!</h3>
      Send me an email and I will get back to you as soon as possible! <br></br>
      <p align = 'center'> chiraphat.b [at] cmu.ac.th </p>
      <a href={`mailto:${contact.email}`}
      aria-label='email'
      className='link link--icon'>
        <EmailIcon />
      </a>
    </section>
  )
}

export default Contact
