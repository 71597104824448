import aboutpic from "./components/Access/mePhoto.jpg"
import smokealertPic from "./components/Access/smokealert-logo-line.png"

const header = {
  // all the properties are optional - can be left empty or deleted
  homepage: 'https://cboonnag.com',
  title: 'CBOONNAG',
}

const about = {
  photo:aboutpic,
  // all the properties are optional - can be left empty or deleted
  name: 'C. Boonnag',
  role: 'clinical data science enthusiast',
  description:
    'Chiraphat’s research interests include mental health, medical education, health informatics, wearable devices, and clinical data science.',
  resume: 'https://www.overleaf.com/read/gvymxtjvrqtb',
  social: {
    linkedin: 'https://www.linkedin.com/in/cboonnag',
    github: 'https://github.com/cboonnag',
    twitter: 'https://twitter.com/cboonnag',
  },
}

const projects = [
  // projects can be added an removed
  // if there are no projects, Projects section won't show up
  {
    name: 'Smokealert',
    photo: smokealertPic,
    description:
      'smokealert - air quality monitoring system using chatbot',
    stack: ['chatbot', 'python','line'],
    sourceCode: '',
    livePreview: 'https://smokealert.med.cmu.ac.th/',
  },
]

const skills = [
  // skills can be added or removed
  // if there are no skills, Skills section won't show up
  // 'HTML',
  // 'CSS',
  // 'JavaScript',
  // 'TypeScript',
  // 'Express',
  // 'MySQL',
  // 'MongoDB',
  // 'React',
  // 'Redux',
  // 'SASS',
  // 'Material UI',
  // 'Git',
  // 'MERN',
]

const contact = {
  // email is optional - if left empty Contact section won't show up
  email: 'chiraphat.b@cmu.ac.th',
}

export { header, about, projects, skills, contact }
